.form__submitWrapper {
  grid-area: submit;
}

.page__wrapper > .form .form__submitWrapper_goTo {
  display: none;
}

@keyframes successForm {
  0% {
    color: initial;
  }
  50% {
    color: transparent;
  }
  100% {
    color: initial;
  }
}

.form {
  margin: auto;
  background-color: var(--color-black);
  color: var(--color-white);
}

.modal .form .form__wrapper {
  opacity: 1;
}

@media screen and (min-width: 900px) {
  .form .form__wrapper {
    opacity: 0;
  }
}

@media screen and (min-width: 600px) {
  .form.animation .form__wrapper,
  .modal.animation .form__wrapper {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
}

.modal .form {
  position: relative;
  margin: 0 0 0 auto;
  height: 100%;
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .mainPage .form {
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media screen and (max-width: 600px) {
  .form {
    margin: 0 auto;
    padding: 60px 15px 120px;
  }
}

/* tmp1 */
@media screen and (max-width: 600px) {
  .form {
    margin: 0 auto;
    padding: 60px 15px 0px;
  }
}

.form__wrapper {
  display: grid;
  grid-template-areas:
    "title form"
    "subtitle form"
    "avatar form"
    "avatar form";
  justify-content: start;
  transition: all 0.25s ease-out;
  box-sizing: border-box;
  margin: auto;
  padding: 240px 46px 195px;
  max-width: 1440px;
  grid-auto-columns: 1fr 1fr;
}

.portfolio .form__wrapper {
  padding: 120px 46px 250px;
}

.projectPage .form__wrapper {
  padding: 230px 46px 260px;
}

@media screen and (max-width: 900px) {
  .portfolio .form__wrapper {
    padding: 60px 46px 250px;
  }
}

@media screen and (max-width: 600px) {
  .portfolio .form__wrapper {
    padding: 0 0 120px;
  }
}

@media screen and (max-width: 900px) {
  .projectPage .form__wrapper {
    padding: 150px 46px 150px;
  }
}

@media screen and (max-width: 600px) {
  .projectPage .form__wrapper {
    padding: 110px 0;
  }
}

@media screen and (max-width: 900px) {
  .form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 180px 46px 180px;
  }
}
@media screen and (max-width: 600px) {
  .form__wrapper {
    padding: 100px 0;
  }
}

@media screen and (max-width: 600px) {
  .main-page .form__wrapper {
    padding: 180px 0;
  }
}

.form__title {
  grid-area: title;
  margin: 0;
  font: var(--font-h-l);
}

@media screen and (max-width: 600px) {
  .form__title {
    font-size: clamp(1rem, 8vw, 2.06rem);
    white-space: wrap;
  }
}

.form__subtitle {
  grid-area: subtitle;
  margin: 24px 0 8px;
  width: 70%;
  line-height: 1.6;
  font: var(--font-ml);
}

@media screen and (max-width: 600px) {
  .form__subtitle {
    margin: 18px 0 8px;
    width: 100%;
    font: var(--font-m);
  }
}

.form_textStyle {
  margin: 0 0 24px;
  border: none;
  border-bottom: 1px solid var(--color-grey);
  background: transparent;
  color: var(--color-grey);
  font-size: 1.125rem;
  line-height: 1.5;
}

.form_textStyle_label {
  position: absolute;
}

.form_textStyle:focus {
  outline: 1px solid transparent;
  border: none;
  border-bottom: 1px solid var(--color-accent);
}
.form__name {
  position: relative;
  grid-area: name;
}

.form__contact {
  grid-area: contact;
}

.form_textStyle::placeholder {
  opacity: 0.8;
}

.form_textStyle_label {
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0.8;
  transition: all 0.25s ease-out;
  color: var(--color-grey);
  font-size: 1.125rem;
}

.form__name_input:focus ~ .form_textStyle_label,
.form__name_input:not(:placeholder-shown) ~ .form_textStyle_label {
  top: -20px;
  left: 0px;
  font-size: 0.95rem;
}

.form__name_input:invalid {
  border-bottom: 1px solid #d22f2f;
}

.form__name_input:placeholder-shown {
  opacity: 0.8;
  border-bottom: 1px solid var(--color-grey);
  color: transparent;
}

.field__input_surname {
  display: none;
}

.form__country {
  grid-area: country;
  margin: 0 0 24px 20px;
}

@media screen and (max-width: 600px) {
  .form__country {
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 750px) {
  .page_light .modal .field__input {
    color: var(--color-grey);
  }
}

.form__agreement {
  grid-area: agreement;
  margin: 20px 0 0 0;
  color: var(--color-grey);
  font: var(--font-xs);
}

.form__agreementButton {
  transition: color 0.25s ease-out;
  cursor: pointer;
  color: var(--color-accent);
}
.form__agreementButton:hover {
  color: var(--color-visited);
}

.form__submitContainer {
  display: flex;
  align-items: center;
  transition: all 0.25s ease-out;
  cursor: pointer;
  margin: 30px 0 0 0;
  border-bottom: 1px solid var(--color-grey);
  padding: 0 0 9px;
  width: fit-content;
  height: min-content;
  white-space: nowrap;
}

.form__submitContainer:hover {
  border-bottom: 1px solid var(--color-accent);
}

.form__submit {
  transition: color 0.25s ease-out;
  cursor: pointer;
  margin: auto 13px auto 0;
  border: none;
  background: transparent;
  color: var(--color-grey);
  font-size: 1.125rem;
  line-height: 1;
  text-align: start;
  text-transform: uppercase;
}

.form__submitContainer:hover .form__submit {
  color: var(--color-accent);
}

@media screen and (max-width: 600px) {
  .form__submitContainer {
    margin: 35px 0 0 0;
    padding: 0 0 1px;
  }
}

@media screen and (max-width: 600px) {
  .form__submit {
    color: var(--color-white);
    font-weight: 500;
    font: var(--font-xs);
    letter-spacing: 0.03rem;
  }
}

.form__submitArrow {
  position: relative;
  transition: all 0.25s ease-out;
  cursor: pointer;
  background-image: url(../../../shared/images/right-arr.webp);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}

.form__submitArrow:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-out;
  background-image: url(../../../shared/images/right-arr_hover.webp);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--color-black);
  width: 100%;
  content: "";
}

.page__wrapper .form__submitContainer:hover .form__submitArrow {
  margin: 0 0 0 9px;
}

.form__submitContainer:hover .form__submitArrow:after {
  opacity: 1;
}

.modalPrices {
  grid-area: price-options;
  margin: 0 0 10px;
}
